
import { Component, Vue } from 'vue-property-decorator';

import { UserModule } from '@/store/modules';
import { ApiService, FeatureService, UserService } from '@/services';
import { IAuthUser, CardDetail } from '@/types/user.types';
import { IBasePaged } from '@/types/base.types';

@Component
export default class DeactivateCards extends Vue {
  last4Digits = this.$route.params.last4Digits;
  id = this.$route.params.id;
  scheme = this.$route.params.scheme;
  agreement = false;
  loading = false;

  cards: IBasePaged<CardDetail> = {
    totalNumberOfPages: 0,
    totalNumberOfRecords: 0,
    items: []
  };

  get user(): IAuthUser | null {
    return UserModule.user;
  }

  /**
   * Deactivate card
   *
   * @param cardId    - Olive card ID
   */
  async deactivateCard(cardId: string): Promise<void> {
    this.loading = true;
    try {
      await ApiService.api.post(`/members/cards/deactivate/${cardId}`);
      this.$notifySuccess('Your credit card is deactivated');
      this.$router.replace({
        name: 'EnrollCard',
        params: { status: 'success' }
      });
      let user = await UserService.fetchUser();
      UserModule.setUser(user);
    } catch (err) {
      this.$notifyError('Deactivation failed. Please try again. ');
      this.$router.replace({
        name: 'EnrollCard',
        params: { status: 'unexpected' }
      });
    }
    this.loading = false;

    // NOTE: When card is deactivated, it might enable round up feature for user, so we
    // need to refresh data for isRoundUpEnabled
    this.updateFeatureStatuses();
  }

  /** Update feature statuses*/
  async updateFeatureStatuses(): Promise<void> {
    try {
      await FeatureService.getFeatureStatuses();
    } catch (err) {
      // Suppress error
    }
  }
}
